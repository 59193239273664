var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero-slider"},[(_vm.sanitizedBlockData.heroSlides.length > 1)?_c('SfCarousel',{attrs:{"data-cy":"hero-slider-carousel","settings":{
      perView: 1,
      breakpoints: {
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
    }}},_vm._l((_vm.sanitizedBlockData.heroSlides),function(heroSlide,i){return _c('SfCarouselItem',{key:i},[_c('HeroSlide',{attrs:{"block-data":heroSlide}})],1)}),1):(_vm.sanitizedBlockData.heroSlides.length === 1)?_c('HeroSlide',{attrs:{"block-data":_vm.blockData.heroSlides[0]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }