<template>
  <div class="hero-slider">
    <SfCarousel
      v-if="sanitizedBlockData.heroSlides.length > 1"
      data-cy="hero-slider-carousel"
      :settings="{
        perView: 1,
        breakpoints: {
          1023: {
            perView: 1,
            peek: {
              before: 0,
              after: 0,
            },
          },
        },
      }"
    >
      <SfCarouselItem
        v-for="(heroSlide, i) in sanitizedBlockData.heroSlides"
        :key="i"
      >
        <HeroSlide :block-data="heroSlide" />
      </SfCarouselItem>
    </SfCarousel>
    <HeroSlide
      v-else-if="sanitizedBlockData.heroSlides.length === 1"
      :block-data="blockData.heroSlides[0]"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';
import { HeroSlide } from './HeroSliderInternal';

export default defineComponent({
  name: 'HeroSlider',
  components: {
    SfCarousel,
    HeroSlide,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { blockData } = props;

    const sanitizedBlockData = computed(() => ({
      ...blockData,
      heroSlides: blockData.heroSlides || [],
    }));

    return {
      sanitizedBlockData,
    };
  },
});
</script>

<style lang="scss" scoped>
.hero-slider {
  min-height: 28.125rem;
  margin-bottom: 5rem;
  @include for-mobile {
    min-height: 18.75rem;
  }
}
</style>

<style lang="scss">
.hero-slider {
  --carousel-width: 100%;
  .sf-carousel {
    &__controls {
      z-index: 1;
      .sf-arrow.sf-button {
        background-color: var(--c-primary);
        --icon-color: var(--c-white);
        &:hover {
          background-color: var(--c-primary-light);
        }
      }
    }
  }
}
</style>
